import React from 'react';
import './App.css';
import { Card } from './components/card/Card';

function App() {
  const [showFrontSide, setShowFrontSide] = React.useState<boolean>(true);
  const [showBackSide, setShowBackSide] = React.useState<boolean>(true);
  const [currentPresetCardType, setPresetCardType] = React.useState<'pokemon' | 'mtg'>('pokemon');

  const onShowFrontSideChanged = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setShowFrontSide(e.currentTarget.checked);
  }, [setShowFrontSide]);

  const onShowBackSideChanged = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setShowBackSide(e.currentTarget.checked);
  }, [setShowBackSide]);

  const onPresetCardTypePokemonChanged = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setPresetCardType('pokemon');
    }
  }, [setPresetCardType]);

  const onPresetCardTypeMtgChanged = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setPresetCardType('mtg');
    }
  }, [setPresetCardType]);

  return (
    <div className="App">
      <fieldset className="Settings">
        <legend>Settings</legend>

        <fieldset style={{ display: 'inline-block' }}>
          <legend>Card type</legend>
          <input type="radio" onChange={onPresetCardTypePokemonChanged} id="PresetCardTypePokemon" checked={currentPresetCardType === 'pokemon'}></input>
          <label htmlFor="PresetCardTypePokemon" style={{ userSelect: 'none' }}>Pokemon</label>
          <input type="radio" onChange={onPresetCardTypeMtgChanged} id="PresetCardTypeMtg" checked={currentPresetCardType === 'mtg'}></input>
          <label htmlFor="PresetCardTypeMtg" style={{ userSelect: 'none' }}>MTG</label>
        </fieldset>

        <fieldset style={{ display: 'inline-block' }}>
          <legend>Show</legend>

          <input type="checkbox" onChange={onShowFrontSideChanged} checked={showFrontSide} id='ShowFrontSide'></input>
          <label htmlFor="ShowFrontSide" style={{ userSelect: 'none' }}>Front</label>
          <input type="checkbox" onChange={onShowBackSideChanged} checked={showBackSide} id='ShowBackSide'></input>
          <label htmlFor="ShowBackSide" style={{ userSelect: 'none' }}>Back</label>
        </fieldset>
      </fieldset>

      <div className="Page" style={{ display: showFrontSide ? undefined : 'none' }}>
        <div className={`PageContent`}>
          <div>
            <div className="Row">
              <Card key='Card1' cardId='1' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card2' cardId='2' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card3' cardId='3' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card4' cardId='4' cardType={currentPresetCardType} showFront={true} />
            </div>
            <div className="Row">
              <Card key='Card5' cardId='5' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card6' cardId='6' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card7' cardId='7' cardType={currentPresetCardType} showFront={true} />
              <Card key='Card8' cardId='8' cardType={currentPresetCardType} showFront={true} />
            </div>
          </div>
        </div>
      </div>
      {(showFrontSide && showBackSide) ? (<div style={{ pageBreakAfter: 'always' }}></div>) : (<></>)}
      <div className="Page" style={{ display: showBackSide ? undefined : 'none' }}>
        <div className={`PageContent`}>
          <div style={{ marginTop: 'auto', WebkitPrintColorAdjust: 'exact', backgroundColor: getBackgroundColor(currentPresetCardType) }}>
            <div className="Row">
              <Card key='Card1' cardId='1' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card2' cardId='2' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card3' cardId='3' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card4' cardId='4' cardType={currentPresetCardType} showFront={false} />
            </div>
            <div className="Row">
              <Card key='Card5' cardId='5' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card6' cardId='6' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card7' cardId='7' cardType={currentPresetCardType} showFront={false} />
              <Card key='Card8' cardId='8' cardType={currentPresetCardType} showFront={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getBackgroundColor(cardType: 'pokemon' | 'mtg'): string {
  const cssCardBackBorderColorPokemon = '#192653';
  const cssCardBackBorderColorMtg = '#000000';

  if (cardType === 'pokemon') {
    return cssCardBackBorderColorPokemon;
  } else if (cardType === 'mtg') {
    return cssCardBackBorderColorMtg;
  } else {
    assertNever(cardType);
  }
}

function assertNever(_x: never): never {
  const callstack = new Error().stack;
  throw new Error(`unexpected assertNever call ${callstack}`);
}

export default App;
