import React from 'react';
import CardBackPokemon from './pokemon_card_back_63mm_88mm.png';
import CardBackMtg from './mtg_card_back_63mm_88mm.png'

const cardFrontWidth = 63;
const cardFrontHeight = 88;
const cardSpacing = 2.5;
const cardBackWidth = cardFrontWidth + (cardSpacing * 2);
const cardBackHeight = cardFrontHeight + (cardSpacing * 2);

const cssCardFrontWidth = toCssMm(cardFrontWidth);
const cssCardFrontHeight = toCssMm(cardFrontHeight)
const cssCardSpacing = toCssMm(cardSpacing);
const cssCardBackWidth = toCssMm(cardBackWidth);
const cssCardBackHeight = toCssMm(cardBackHeight);
const cssCardCornerRadius = toCssMm(3);

function toCssMm(value: number): string {
    return `${value}mm`;
}

export interface CardProps {
    cardId: string,
    cardType: 'pokemon' | 'mtg',
    showFront: boolean;
}

export const Card = (props: CardProps) => {
    const filePickerKey = `FilePicker_${props.cardId}`;
    const [src, setSrc] = React.useState<string | null>(null);
    const [currentDragEnter, setDragEnter] = React.useState<boolean>(false);

    const dropHandler = React.useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        console.log("File(s) dropped");

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                const item = ev.dataTransfer.items[i];
                if (item.kind === "file") {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        setSrc(reader.result as string);
                    });
                    reader.readAsDataURL(item.getAsFile()!);
                    break;
                }
            }
        }
    }, [setSrc]);

    const dragOverHandler = React.useCallback((ev: any) => {
        ev.preventDefault();
    }, []);

    const onFilePickerChanged = React.useCallback(() => {
        console.log('hello');

        const file = (document.getElementById(filePickerKey) as HTMLInputElement)?.files;
        if (file) {
            const fileItem = file.item(0);
            if (fileItem) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    setSrc(reader.result as string);
                });
                reader.readAsDataURL(fileItem);
            }
        }
    }, [filePickerKey, setSrc]);

    return (
        <div
            onDrop={props.showFront ? dropHandler : undefined}
            onDragOver={props.showFront ? dragOverHandler : undefined}
            style={{ width: cssCardBackWidth, height: cssCardBackHeight }}
        >
            {
                !props.showFront ?
                    (
                        <img
                            src={props.cardType === 'pokemon' ? CardBackPokemon : CardBackMtg}
                            style={{ margin: cssCardSpacing, width: cssCardFrontWidth, height: cssCardFrontHeight }}
                            alt={`${props.cardType} card back`}
                        />
                    ) : src ?
                        (
                            <img
                                src={src}
                                style={{ margin: cssCardSpacing, borderRadius: cssCardCornerRadius, overflow: 'hidden', width: cssCardFrontWidth, height: cssCardFrontHeight }}
                                alt="card front"
                            />
                        )
                        : (
                            <div key='srcUnset' onDrop={dropHandler} onDragOver={dragOverHandler} onDragEnter={() => setDragEnter(true)} onDragLeave={() => setDragEnter(false)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box', border: '2px dashed blue', borderRadius: cssCardCornerRadius, width: cssCardFrontWidth, height: cssCardFrontHeight, margin: cssCardSpacing, backgroundColor: currentDragEnter ? '#ccc' : undefined }}>
                                <input type="file" id={filePickerKey} onChange={onFilePickerChanged} style={{ display: 'none' }} accept="image/png, image/jpeg, image/avif, image/bmp, image/gif, image/tiff, image/webp, image/apng" />
                                <button onClick={() => document.getElementById(filePickerKey)!.click()}>Browse...</button>

                                <p>or drop an image here</p>
                            </div>
                        )
            }
        </div>
    )
}


export { }
